import React from 'react';
import { connect } from 'react-redux';
import Product from './Product';
import PropTypes from 'prop-types';
import { showProductDetails } from '../../actions/productDetails';
import Spinner from '../Utils/Spinner';
import * as qs from 'qs';
import history from '../../history';
import * as _ from 'lodash';
import { bindActionCreators } from 'redux';
import { toggleProductFavorite } from '../../actions/routines';

class BoxProductContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            windowWidth: 0,
            showItems: 5,
            page: 1,
            limit: 50,
        };
    }

    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.limit = '50';

        this.props.onFetchData(queryParams);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(prevProps.data, this.props.data) ||
            (this.state.products.length === 0 && this.props.data.length)
        ) {
            this.setState({
                page: this.props.currentPage === 1 ? 1 : this.state.page,
                products:
                    this.props.currentPage === 1
                        ? this.props.data
                        : [...this.state.products, ...this.props.data],
            });
        }
    }

    openProductDetails = (id) => {
        const { showProductDetails, deliveryDate } = this.props;

        showProductDetails(id, deliveryDate);
    };

    loadMore = () => {
        const { limit, page } = this.state;
        const { loading } = this.props;

        if (loading || !this.props.hasMore) {
            return;
        }

        const queryParams = qs.parse(history.location.search.slice(1));

        queryParams.limit = limit;
        queryParams.page = page + 1;

        this.setState({
            page: this.state.page + 1,
        });

        this.props.onFetchData(queryParams);
    };

    toggleProductFavorite = (id) => {
        const { toggleProductFavorite } = this.props;

        toggleProductFavorite(id);

        const products = this.state.products.map((product) => {
            if (product.id === id) {
                product.isFavourite = !product.isFavourite;
            }

            return product;
        });

        this.setState({ products });
    };

    render() {
        const { loading, hasMore } = this.props;
        const products = _.uniqWith(this.state.products, _.isEqual);

        return (
            <React.Fragment>
                {loading && this.state.page === 1 && (
                    <div className="d-flex justify-content-center my-4">
                        <Spinner className="brand-color" />
                    </div>
                )}
                <Product
                    hasMore={hasMore}
                    isFirstLoad={loading && this.state.page === 1}
                    loadMore={this.loadMore}
                    products={products}
                    openProductDetails={this.openProductDetails}
                    toggleProductFavorite={this.toggleProductFavorite}
                />
            </React.Fragment>
        );
    }
}

BoxProductContainer.protoTypes = {
    onFetchData: PropTypes.func,
    hasMore: PropTypes.bool,
    currentPage: PropTypes.number,
    loading: PropTypes.bool,
};

BoxProductContainer.defaultProps = {
    products: [],
};

const mapStateToProps = (state) => {
    return {
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            { showProductDetails, toggleProductFavorite },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoxProductContainer);
