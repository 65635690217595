import { Spinner } from 'mdbreact';
import React from 'react';
import PropTypes from 'prop-types';
import ProductBox from './components/ProducBox';
import InfiniteScroll from 'react-infinite-scroll-component';

const Product = ({
    products,
    openProductDetails,
    loadMore,
    hasMore,
    isFirstLoad,
    toggleProductFavorite,
}) => {
    return (
        <InfiniteScroll
            className="mb-5 box-products"
            hasMore={!isFirstLoad && hasMore}
            next={loadMore}
            dataLength={products.length}
            loader={
                <div className="flex-center">
                    <Spinner className="brand-color" />
                </div>
            }
        >
            {products.map((product, index) => {
                return (
                    <ProductBox
                        index={index}
                        key={product.id}
                        product={product}
                        openProductDetails={openProductDetails}
                        toggleProductFavorite={toggleProductFavorite}
                    />
                );
            })}
        </InfiniteScroll>
    );
};

Product.propTypes = {
    hasMore: PropTypes.bool,
    isLoading: PropTypes.any,
    loadMore: PropTypes.func,
    products: PropTypes.array,
    title: PropTypes.string,
    titleValues: PropTypes.object,
};

Product.defaultProps = {
    title: 'menu.novelty',
    titleValues: {},
};

export default Product;
